<template>
  <div>
    <v-btn v-if="step !== 'Finish'" icon @click="previous" class="ml-minus-2">
      <v-icon>
        mdi-arrow-left
      </v-icon>
    </v-btn>
    <h1 class="my-5 font-weight-medium">{{ $t('views.Withdraw.title') }}</h1>
    <component
      :is="step"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'Withdraw',
  components: {
    Amount: lazyLoad('views/Profile/Withdraw/Amount'),
    Confirm: lazyLoad('views/Profile/Withdraw/Confirm'),
    Finish: lazyLoad('views/Profile/Withdraw/Finish'),
  },
  computed: {
    ...mapGetters('withdraw', [
      'step',
    ]),
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    ...mapActions('withdraw', [
      'reset',
      'nextStep',
      'previousStep',
    ]),
    previous() {
      if (this.step === 'Amount') {
        this.$router.go(-1);
      } else {
        this.previousStep();
      }
    },
  },
};
</script>
